import React from "react"
import { Link } from "gatsby"
import { Icon, InlineIcon } from "@iconify/react"
import bxListPlus from "@iconify/icons-bx/bx-list-plus"
import Information from "./information"

const Onboarding = () => {
  return (
    <div>
      <Information />
      <h1>Welcome!</h1>
      <p className="my-0">Hello and welcome to the TastyFridge Demo</p>
      <button className="shadow bg-bgGreen hover:bg-bgGreen-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-2 rounded my-4">
        <Link to="/search-ingredients/">
          <InlineIcon
            icon={bxListPlus}
            style={{ color: "#F6F9FC", fontSize: "30px" }}
            className="inline-block content-center"
          />
          <span className="px-2 content-center align-middle">Get Started</span>
        </Link>
      </button>
      <p>
        Let's get started straight away shall we, but first just a few{" "}
        <i>disclaimers</i>. This is a demo after all so some features might not
        work yet and our recipe chef is still working out it's kinks and knacks
        so the recipes that he suggests might not be a perfect fit or slighly
        off, but he loves feedback so please{" "}
        <Link to="/feedback/" className="underline color-blue-400">
          let him know
        </Link>{" "}
        if he got it wrong by clicking on the feedback button on the top right.
      </p>
      <h2>Getting Started</h2>
      <p>
        Now let's get to the good stuff to get started all you need to do is
        click on the list with a plus icon up in the navigation bar and type in
        the ingredients you have. We also pre made lists with common ingredients
        in case you don't want to spend to much time adding basic things like
        salt or milk.
      </p>
    </div>
  )
}

export default Onboarding
