import React from "react"
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { getIngredients } from "../utils/storage"
import Onboarding from "../components/onboarding"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      {getIngredients().length > 0 ? navigate("/my-fridge/") : <Onboarding />}
    </Layout>
  )
}

export default IndexPage
